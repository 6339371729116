<template>
  <div v-if="success">
    <b-alert show variant="success"><icon-text icon="spinner" spin>Connected to WiFi - stand by for redirect...</icon-text></b-alert>
  </div>
  <div v-else-if="challenge">
    <b-card sub-title="Guest WiFi Access" :title="challenge.tenant.name" class="shadow-sm mb-4">
      <b-alert v-if="!challenge.enabled" show variant="danger">Guest access is currently unavailable.</b-alert>
      <template v-else>
        <p>By using this service, you are confirming that you accept the <router-link :to="{ name: 'tcs' }">terms and conditions</router-link> as the basis of your use of the wireless internet access provided.</p>
        <b-checkbox v-model="acceptTerms">Tick here to accept the terms and condition.</b-checkbox>
      </template>
    </b-card>

    <template v-if="challenge.enabled">
      <b-alert v-if="!atLeastOneChallenge" show variant="warning">Guest access has not been configured correctly.  Please contact the system administrator.</b-alert>

      <b-card v-if="challenge.modules.password" class="shadow-sm mb-4" no-body>
        <b-overlay :show="busy">
          <b-card-header>Password Login</b-card-header>
          <b-card-body>
            <b-alert v-if="passwordError" show variant="danger" class="mb-2">{{passwordError}}</b-alert>
            <b-input type="password" v-model="loginPassword" placeholder="Enter password" class="mb-2" :disabled="busy || !acceptTerms" />
            <b-btn variant="primary" :disabled="loginPassword.length === 0 || busy || !acceptTerms" @click="loginViaPassword()">Login</b-btn>
          </b-card-body>
        </b-overlay>
      </b-card>

      <b-card v-if="challenge.modules.account" title="Guest Account Login" class="shadow-sm mb-4">
      </b-card>
    </template>
  </div>
  <div v-else-if="error">
    <b-alert show variant="danger"><strong>Error:</strong> {{error}}</b-alert>
  </div>
  <div v-else>
    <b-alert show variant="info"><icon-text icon="spinner" spin>Connecting...</icon-text></b-alert>
  </div>
</template>

<script>
import IconText from '../../components/IconText'

export default {
  components: { IconText },
  data: () => ({
    challenge: null,
    error: null,
    loginPassword: '',
    passwordError: null,
    busy: false,
    success: false,
    acceptTerms: false
  }),
  computed: {
    atLeastOneChallenge () {
      return this.challenge && this.challenge.enabled && (this.challenge.modules.password || this.challenge.modules.account)
    }
  },
  methods: {
    async reload () {
      this.challenge = null
      this.error = null
      this.loginPassword = ''

      try {
        this.challenge = await API.guestLogin.get_challenge(this.$route.params.site)
      } catch (error) {
        this.error = error
      }
    },
    async loginViaPassword () {
      this.busy = true
      this.passwordError = null
      try {
        await API.guestLogin.login_via_password(this.$route.params.site, {
          ap: this.$route.query.ap,
          id: this.$route.query.id,
          t: this.$route.query.t,
          ssid: this.$route.query.ssid,
          pwd: this.loginPassword
        })

        this.success = true
        window.location.href = this.$route.query.url
      } catch (error) {
        this.passwordError = error
      } finally {
        this.busy = false
      }
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
